// extracted by mini-css-extract-plugin
export var btnGroup = "header-module--btnGroup--5cd99";
export var container = "header-module--container--621b5";
export var content = "header-module--content--8c823";
export var date = "header-module--date--6faf6";
export var header = "header-module--header--48f8f";
export var link = "header-module--link--255f6";
export var memorial = "header-module--memorial--41833";
export var name = "header-module--name--d5d7f";
export var text = "header-module--text--ca21c";
export var title = "header-module--title--7d3e4";
export var wrapper = "header-module--wrapper--83947";